import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Mobility: Upperbody & Core`}</p>
    <p>{`then,`}</p>
    <p>{`25:00 Ascending AMRAP of:`}</p>
    <p><em parentName="p">{`(5/10/15/20/25/etc)`}</em></p>
    <p>{`Calorie Row`}</p>
    <p>{`Situps`}</p>
    <p>{`Double Unders`}</p>
    <p>{`V Ups`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      